/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    h2: "h2",
    ol: "ol",
    li: "li"
  }, _provideComponents(), props.components), {Variable, TextBlock, QuinStreet} = _components;
  if (!QuinStreet) _missingMdxReference("QuinStreet", true);
  if (!TextBlock) _missingMdxReference("TextBlock", true);
  if (!Variable) _missingMdxReference("Variable", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, React.createElement(Variable, {
    name: "region",
    formatting: "capitalize",
    defaultValue: "Attention"
  }), ", ", React.createElement(Variable, {
    name: "city",
    formatting: "capitalize",
    defaultValue: "Attention"
  }), " -"), " A new online smart system can significantly reduce how much you pay for car insurance, and insurance companies won't tell you anything about it. "), "\n", React.createElement(_components.p, null, "60% of drivers in the state are overcharged by insurance companies, but most drivers don't even know this. By using this system, you can say goodbye to the middleman and get personalized insurance quotes, saving more than $500 per year. And the best part, It’s very easy to use."), "\n", React.createElement(_components.p, null, "We've spoken to a few drivers who, for years, have paid insurance companies thousands of dollars and were tired by them. At first, they were skeptical, but after they used the system, they were amazed."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "\"I reduced my premiums by 65%\", said Josh excitedly. \"I was able to upgrade my car and get some much-needed repairs done.\"")), "\n", React.createElement(TextBlock, {
    slug: "car-insurance-example-savings"
  }), "\n", React.createElement(_components.h2, {
    id: "heres-how-to-use-it"
  }, "Here's how to use it:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Tap on your age and enter your zip code."), "\n", React.createElement(_components.li, null, "Fill out some basic information to review quotes from the top companies in your area."), "\n", React.createElement(_components.li, null, "Choose the best quote for you"), "\n"), "\n", React.createElement(QuinStreet, {
    src: "690606",
    ui: "whitelabel",
    variant: "v3",
    title: "Explore Top-Rated Insurers Near You!",
    titleComponent: "h3",
    subTitle: "Use the filters below to get the best custom insurance offers for you",
    listingsTitle: "Click on 2 or more insurers below to compare quotes and maximize your savings.",
    buttonText: "Complete questions above",
    activeButtonText: "Get Quotes!"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
