const config = {
  auto: {
    src: 691273,
    inventorytype: 'generic-listing-incontent',
    ui: {
      zip: {
        691273: {
          src: 691273,
          inventorytype: 'zipwidget',
          redirect_location: 'https://o1.qnsr.com/cgi/r?;n=203;c=1682290;s=33142;x=7936;f=202302101023390;u=j;z=TIMESTAMP;',
          skip_original_src: true,
        },
      },
      whiteLabel: {
        691273: {
          src: 691273,
          href: 'https://o1.qnsr.com/cgi/r?;n=203;c=1682290;s=33142;x=7936;f=202302101023390;u=j;z=TIMESTAMP;',
        },
      },
      lightbox: {
        691593: {
          src: 691593,
        },
      },
    },
  },
};

export default config;
