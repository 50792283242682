module.exports = [{
      plugin: require('../node_modules/@taboola/venus-core/plugins/plugin-material-ui/gatsby-browser.tsx'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/venus_builder/venus-ci/workspace/kbpb/master/node_modules/@taboola/venus-core/src/layouts/page-layout.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"icon":false,"isIconAfterHeader":false,"elements":["h2"],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/@taboola/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/KBPB-logo-small.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a6ace1445402c3b3076a7baea31ec042"},
    },{
      plugin: require('../node_modules/@taboola/venus-core/plugins/single-article/gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@taboola/venus-core/gatsby-browser.tsx'),
      options: {"plugins":[],"siteMetadata":{"site":"KBPB","siteUrl":"https://knowbetterplanbetter.com","image":"/KBPB-logo.svg","iconPath":"static/KBPB-logo-small.svg","title":"Know Better Plan Better","titleTemplate":"%s · Know Better Plan Better","facebook":{"link":"https://facebook.com/KBPBsite"},"twitter":{"link":"https://x.com/know_plan","handle":"@know_plan"},"taboola":{"pixel":1493530,"feed":"venus-knowbetterplanbetter-publisher"},"google":{"analytics":"UA-73290888-15","tagManager":"GTM-NHSQLJR","verificationToken":"P0dMzfytTI4zUpfkhXs4GJobMZL0o6bMTGZvTQXmPic"},"impact":{"verificationToken":603268907}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-decap-cms/gatsby-browser.js'),
      options: {"plugins":[],"manualInit":true,"enableIdentityWidget":false,"htmlTitle":"CMS - Know Better Plan Better","htmlFavicon":"static/KBPB-logo-small.svg","modulePath":"/home/venus_builder/venus-ci/workspace/kbpb/master/node_modules/@taboola/venus-core/src/cms/cms.js"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
