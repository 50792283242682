/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.p, null, "Debt consolidation lets you combine all your frustrating, high-interest credit card balances into one easy-to-manage loan with a potentially lower rate and more flexible terms.** ", React.createElement(_components.a, {
    href: "#fiona-anchor"
  }, "Your new loan"), "** may help you pay less in total interest, which can help you get out of debt faster.");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
