/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components), {ALink} = _components;
  if (!ALink) _missingMdxReference("ALink", true);
  return React.createElement(_components.p, null, "Prepare to upgrade your casual footwear with shoes that are the perfect combination of comfort and style. With the ", React.createElement(ALink, {
    to: "skechers-relaxed-fit-melson-mozley",
    newTab: "true"
  }, "Skechers Relaxed Fit: Melson - Mozley"), " you will be feeling like walking on clouds.");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
