/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    img: "img"
  }, _provideComponents(), props.components), {ALink} = _components;
  if (!ALink) _missingMdxReference("ALink", true);
  return React.createElement(ALink, {
    to: "monarch-money-managment-tool",
    type: "banner"
  }, React.createElement(_components.img, {
    src: "../images/monarch-ss.png",
    alt: ""
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
