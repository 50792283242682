/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td"
  }, _provideComponents(), props.components), {ALink} = _components;
  if (!ALink) _missingMdxReference("ALink", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Choosing a debt consolidation loan company can seem daunting when there are so many options available. That’s why we have done the research to find the best debt consolidation loans out there, so you can pick the one that works best for you."), "\n", React.createElement(_components.p, null, "Use this table to make it easier to compare and contrast these best debt consolidation loan companies."), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "center"
  }, "Company"), React.createElement(_components.th, {
    align: "center"
  }, "APR range"), React.createElement(_components.th, {
    align: "center"
  }, "Loan amounts"), React.createElement(_components.th, {
    align: "center"
  }, "Minimum credit score (reported)"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, React.createElement(ALink, {
    to: "sofi",
    newTab: "true"
  }, "Sofi")), React.createElement(_components.td, {
    align: "center"
  }, "4.9%-18.1%"), React.createElement(_components.td, {
    align: "center"
  }, "$5k-$100k"), React.createElement(_components.td, {
    align: "center"
  }, "Good")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, React.createElement(ALink, {
    to: "onemain-financial",
    newTab: "true"
  }, "OneMain Financial")), React.createElement(_components.td, {
    align: "center"
  }, "18%-35.9%"), React.createElement(_components.td, {
    align: "center"
  }, "$1.5k-$20k"), React.createElement(_components.td, {
    align: "center"
  }, "None")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, React.createElement(ALink, {
    to: "marcus",
    newTab: "true"
  }, "Marcus by Goldman Sachs")), React.createElement(_components.td, {
    align: "center"
  }, "6.7%-19.7%"), React.createElement(_components.td, {
    align: "center"
  }, "$3.5k-$40k"), React.createElement(_components.td, {
    align: "center"
  }, "Good")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, React.createElement(ALink, {
    to: "figure",
    newTab: "true"
  }, "Figure")), React.createElement(_components.td, {
    align: "center"
  }, "5.7%-22.9%"), React.createElement(_components.td, {
    align: "center"
  }, "$5k-$50k"), React.createElement(_components.td, {
    align: "center"
  }, "Good")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, React.createElement(ALink, {
    to: "upgrade",
    newTab: "true"
  }, "Upgrade")), React.createElement(_components.td, {
    align: "center"
  }, "5.9%-35.9%"), React.createElement(_components.td, {
    align: "center"
  }, "$1k-$50k"), React.createElement(_components.td, {
    align: "center"
  }, "Fair")))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
