/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    a: "a",
    img: "img"
  }, _provideComponents(), props.components), {L1, Default, Variant, TextBlock} = _components;
  if (!Default) _missingMdxReference("Default", true);
  if (!L1) _missingMdxReference("L1", true);
  if (!TextBlock) _missingMdxReference("TextBlock", true);
  if (!Variant) _missingMdxReference("Variant", true);
  return React.createElement(React.Fragment, null, React.createElement(L1, null, React.createElement(Default, null, React.createElement(_components.p, null, "The past year wreaked havoc on our lives and our finances and all through it, the Federal Reserve kept rates at record levels, currently at ", React.createElement(_components.strong, null, "0.25%"), ". If interest rates are at all-time lows, why is the average APR on a new credit card account just over 18%? In some cases, consumers are getting hit with credit card interest charges of ", React.createElement(_components.strong, null, "24.99%"), ", or even higher than that. If you hope to benefit from these low rates, you need to ", React.createElement(_components.a, {
    href: "#fiona-anchor"
  }, "refinance or consolidate credit card bills"), " with a low interest personal loan."), React.createElement(_components.img, {
    src: "https://media.graphcms.com/CoHWhMZQRpaOnURvPBll",
    alt: "money-worries.jpg"
  })), React.createElement(Variant, {
    id: "fiona-no-image"
  }, React.createElement(_components.p, null, "The past year wreaked havoc on our lives and our finances and all through it, the Federal Reserve kept rates at record levels, currently at ", React.createElement(_components.strong, null, "0.25%"), ". If interest rates are at all-time lows, why is the average APR on a new credit card account just over 18%? In some cases, American consumers are getting hit with credit card interest charges of ", React.createElement(_components.strong, null, "24.99%"), ", or even higher than that. ​If you hope to benefit from these low rates, you need to ", React.createElement(_components.a, {
    href: "#fiona-anchor"
  }, "refinance or consolidate credit card bills"), " with a low interest personal loan."))), "\n", React.createElement(TextBlock, {
    slug: "alt-editors-note",
    variant: "alt-editors-note"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
