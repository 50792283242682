const SITE_URL_OVERRIDE = process.env.GATSBY_SITE_URL_OVERRIDE;

const siteMetadata = {
  site: 'KBPB',
  siteUrl: SITE_URL_OVERRIDE || 'https://knowbetterplanbetter.com', // No trailing slash allowed!
  image: '/KBPB-logo.svg',
  iconPath: 'static/KBPB-logo-small.svg',
  title: 'Know Better Plan Better',
  titleTemplate: '%s · Know Better Plan Better',
  facebook: {
    link: 'https://facebook.com/KBPBsite',
  },
  twitter: {
    link: 'https://x.com/know_plan',
    handle: '@know_plan',
  },
  taboola: {
    pixel: 1493530,
    feed: 'venus-knowbetterplanbetter-publisher',
  },
  google: {
    analytics: 'UA-73290888-15',
    tagManager: 'GTM-NHSQLJR',
    verificationToken: 'P0dMzfytTI4zUpfkhXs4GJobMZL0o6bMTGZvTQXmPic',
  },
  impact: {
    verificationToken: 603268907,
  },
};

module.exports = { siteMetadata };
