/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Are you sick of your high monthly car payments? Perhaps you took out a loan when your credit was bad or when interest rates were high and now you’re wondering if there’s a better option out there for you. Refinancing your car loan can make a huge difference to your monthly payment, so you can spend your hard-earned money on what matters most to you."), "\n", React.createElement(_components.p, null, "Intrigued? Read on to learn more about how refinancing your car loan can help you save."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
